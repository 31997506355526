@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
* {
  box-sizing: border-box;
}
body {
  font-family: Rubik, sans-serif;
  font-size: 18px;
  line-height: 150%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Ubuntu, Arial, sans-serif;
}

b {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 150%;
}
input[type="text"] {
  padding: 8px;
  border: 1px solid #aaa;
}
a {
  color: #a330a2;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a,
button {
  cursor: pointer;
}
button {
  background: #a330a2;
  color: white;
  border: 0;
  padding: 10px 30px;
}

/* Change autocomplete styles in WebKit */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: 0;
  font-family: Rubik, sans-serif !important;
  background-color: rgb(250, 255, 189);
  -webkit-transition-delay: 99999s;
} */

